import { memo, useState } from 'react';

import i18n from '../../i18n/i18n';

const Video = memo(() => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <section
        className='flex flex-column flex-center padding hero video-hero center zoom no-select'
        style={{ backgroundImage: `url('/images/heros/hero-2.jpg')` }}
      >
        <p>{i18n.t('videoLine1')}</p>

        <h2>
          {i18n.t('videoLine2')}
          <br />
          {i18n.t('videoLine3')}
        </h2>

        <button type='button' className='play-button' onClick={() => setOpened(true)}>
          <span className='material-icons'>play_arrow</span>
        </button>
      </section>
      {opened && (
        <div className='gallery-opened'>
          <video
            width='100%'
            height='100%'
            controls
            poster='/images/heros/hero-2.jpg'
            preload='auto'
          >
            <source src='/videos/video1.mp4' type='video/mp4' />
            <source src='/videos/video2.mp4' type='video/mp4' />
            {i18n.t('videoSupport')}
          </video>
          <div
            aria-hidden='true'
            className='gallery-closer flex flex-center no-select'
            onClick={() => setOpened(null)}
          >
            <span className='material-icons'>close</span>
          </div>
        </div>
      )}
    </>
  );
});

export default Video;
