import { memo } from 'react';
import { Link } from 'react-scroll';

import i18n from '../../i18n/i18n';

const MainHero = memo(() => (
  <section
    className='flex flex-column flex-center h100 hero main-hero no-select'
    style={{ backgroundImage: `url('/images/heros/hero-3.jpg')` }}
    id='sitetop'
  >
    <h1 className='center'>{i18n.t('siteName')}</h1>
    <p className='center'>{i18n.t('mainHero')}</p>
    <Link to='sitereservation' smooth offset={-100} className='button button-blue button-large'>
      {i18n.t('forInfo')}
    </Link>
  </section>
));

export default MainHero;
