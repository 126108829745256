import { memo, useState, useEffect } from 'react';

import FaqItem from '../Common/FaqItem';

import i18n from '../../i18n/i18n';

const faq = [
  {
    id: 1,
    showcase: true,
    question: 'Kaç kişiye kadar konaklanabilmektedir?',
    answer: 'Loft katta çift kişilik yatak ve giriş katında açılabilen koltuk mevcuttur.',
  },
  {
    id: 2,
    showcase: true,
    question: 'Fiyata neler dahildir?',
    answer: '4 kişiye kadar konaklama ve serpme kahvaltı dahildir.',
  },
  {
    id: 3,
    showcase: true,
    question: 'Öğle ve akşam yemeği imkanı var mıdır?',
    answer:
      'Kendimize ait bir restoranımız bulunmadığından öğle ve akşam yemeği servisimiz bulunmamaktadır. Dilerseniz öğle ve akşam yemeklerini bungalovumuzun içerisinde bulunan mini mutfakta kendiniz hazırlayabilir, dilerserniz bahçede bulunan barbekümüzden ücretsiz bir şekilde faydalanabilirsiniz.',
  },
  {
    id: 4,
    showcase: true,
    question: 'Evlilik cüzdanı şartı var mıdır?',
    answer: 'Evlilik cüzdanı şartımız bulunmamaktadır.',
  },
  {
    id: 5,
    showcase: false,
    question: 'Minimum konaklama şartı var mıdır?',
    answer:
      'Hafta içi minimum konaklama şartımız bulunmamaktadır. Hafta sonu ise minimum 2 gece konaklama şartımız bulunmaktadır.',
  },
  {
    id: 6,
    showcase: false,
    question: 'Kaparo talebiniz var mı, varsa ne kadar?',
    answer: 'Kaparo talebimiz toplam ücretin yüzde ellisidir.',
  },
  {
    id: 7,
    showcase: false,
    question: 'Evcil hayvan kabul ediyor musunuz?',
    answer: 'Küçük ırk hayvan kabul etmekteyiz.',
  },
];

const Faq = memo(() => {
  const [opened, setOpened] = useState(0);
  const [enlarged, setEnlarged] = useState(false);
  const [faqQuestions, setFaqQuestions] = useState([]);

  useEffect(() => {
    if (enlarged) {
      setFaqQuestions(faq);
    } else {
      setFaqQuestions(faq.filter((f) => f.showcase === true));
    }
  }, [enlarged]);

  const handleOpen = (id) => {
    if (opened === id) {
      setOpened(0);
    } else {
      setOpened(id);
    }
  };

  const handleEnlarge = () => {
    setEnlarged(!enlarged);
  };

  return (
    <section className='padding-half' id='sitefaq'>
      <div className='flex flex-row flex-center'>
        <div className='container'>
          <div className='content'>
            <h2 className='no-select'>{i18n.t('faqLong')}</h2>
          </div>
        </div>
      </div>
      <div className='flex flex-row flex-center'>
        <div className='container'>
          <div className='faq flex flex-column'>
            {faqQuestions.map((item) => (
              <FaqItem
                id={item.id}
                opened={opened === item.id}
                question={item.question}
                answer={item.answer}
                key={item.id.toString()}
                onClick={() => handleOpen(item.id)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className='faq-all flex flex-center'>
        <button
          type='button'
          className='button button-blue button-large'
          onClick={() => handleEnlarge()}
        >
          <span className='material-icons'>{enlarged ? 'arrow_upward' : 'arrow_downward'}</span>
          {enlarged ? i18n.t('showImportant') : i18n.t('showAll')}
        </button>
      </div>
    </section>
  );
});

export default Faq;
