import { createContext, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Database from '../tools/Database';

const tableName = 'language';

const initialState = {
  language: {
    appLanguage: 'tr',
  },
  addLanguage: () => {},
};

export const LanguageContext = createContext(initialState);

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    if (language === null) {
      const result = Database.getData(tableName);

      if (result) {
        setLanguage(result);
      } else {
        setLanguage(initialState.language);
      }
    }
  }, []);

  /**
   * Add or update language parameters in database.
   *
   * @param {object} details - Language data
   */
  const addLanguage = (details) => {
    // TODO: change language from provider

    setLanguage(details);

    Database.setData(tableName, details);
  };

  /**
   * Create context values.
   */
  const contextValue = useMemo(
    () => ({
      language,
      addLanguage: (details) => addLanguage(details),
    }),
    []
  );

  return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LanguageProvider;
