import axios from 'axios';

/**
 * Get json value from server.
 *
 * @param {object} parameters - Request parameters
 */
const getResponse = async (parameters) => {
  let errorData = {};
  let successData = {};

  if (parameters === null || parameters === undefined || !parameters.method) {
    errorData = {
      status: 0,
      title: 'Parameters or method not found.',
    };

    return errorData;
  }

  const headers = {};

  if (parameters.headers) {
    // eslint-disable-next-line array-callback-return
    parameters.headers.map((header) => {
      const key = Object.keys(header)[0];
      const value = Object.values(header)[0];

      headers[key] = value;
    });
  }

  try {
    let response = {};

    if (parameters.method === 'GET') {
      response = await axios.get(parameters.url, { headers, timeout: 15000 });
    } else {
      response = await axios.post(parameters.url, parameters.data, { headers, timeout: 15000 });
    }

    if (response.status === 200) {
      successData = {
        status: response.status,
        data: response.data,
      };

      return successData;
    }

    errorData = {
      status: response.status,
      data: response.data,
    };

    return errorData;
  } catch (error) {
    if (error.response) {
      errorData = {
        status: error.response.status,
        data: error.response.data,
      };

      return errorData;
    }

    if (error.request) {
      errorData = {
        status: 0,
        data: 'No response received. Maybe DNS not resolved or server not found.',
      };

      return errorData;
    }

    errorData = {
      status: 0,
      data: error.message,
    };

    return errorData;
  }
};

const Request = {
  getResponse,
};

export default Request;
