import { memo } from 'react';
import PropTypes from 'prop-types';

const FaqItem = memo(({ question, answer, opened, onClick }) => (
  <>
    <button type='button' className='faq-container left' onClick={onClick}>
      <div className='faq-item'>
        <div className='faq-close-icon'>
          {opened ? (
            <span className='material-icons'>expand_less</span>
          ) : (
            <span className='material-icons'>expand_more</span>
          )}
        </div>
        <p>{question}</p>
      </div>
    </button>
    {opened && <blockquote>{answer}</blockquote>}
  </>
));

FaqItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  opened: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FaqItem;
