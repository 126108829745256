import { Link } from 'react-router-dom';

const NotFoundScreen = () => (
  <section
    className='flex flex-column flex-center h100 hero main-hero no-select'
    style={{ backgroundImage: `url('/images/heros/hero-2.jpg')` }}
    id='sitetop'
  >
    <h1>404</h1>
    <p>Aradığınız sayfa bulunamadı. Ana sayfaya giderek devam edebilirsiniz.</p>
    <Link to='/' className='button button-blue button-large'>
      Ana sayfa
    </Link>
  </section>
);

export default NotFoundScreen;
