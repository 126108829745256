import { memo, useState } from 'react';

import Request from '../../tools/Request';

import i18n from '../../i18n/i18n';

const Contact = memo(() => {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    name: '',
    subject: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formValues.name === '') {
      setMessage({
        type: 'error',
        title: 'Bir hata bulundu',
        message: 'Lütfen adınızı ve soyadınızı giriniz.',
        buttonTitle: 'Geri dön',
      });
    } else if (formValues.subject === '') {
      setMessage({
        type: 'error',
        title: 'Bir hata bulundu',
        message: 'Lütfen bir konu seçiniz.',
        buttonTitle: 'Geri dön',
      });
    } else if (formValues.phone === '') {
      setMessage({
        type: 'error',
        title: 'Bir hata bulundu',
        message: 'Lütfen telefon numaranızı giriniz.',
        buttonTitle: 'Geri dön',
      });
    } else if (formValues.phone.length < 10) {
      setMessage({
        type: 'error',
        title: 'Bir hata bulundu',
        message: 'Lütfen doğru bir telefon numarası giriniz.',
        buttonTitle: 'Geri dön',
      });
    } else if (formValues.message === '') {
      setMessage({
        type: 'error',
        title: 'Bir hata bulundu',
        message: 'Lütfen mesajınızı giriniz.',
        buttonTitle: 'Geri dön',
      });
    } else {
      setLoading(true);

      const parameters = {
        method: 'POST',
        url: 'https://submit-form.com/bqntMAwv',
        data: formValues,
        headers: [{ 'Content-Type': 'application/json' }, { Accept: 'application/json' }],
      };

      const request = await Request.getResponse(parameters);

      if (request.status === 200) {
        setMessage({
          type: 'success',
          title: 'Başarılı',
          message: 'Mesajınız gönderildi. Teşekkür ederiz.',
          buttonTitle: 'Geri dön',
        });

        setFormValues({
          name: '',
          subject: '',
          phone: '',
          message: '',
        });

        setLoading(false);
      } else {
        setMessage({
          type: 'error',
          title: 'Bir hata bulundu',
          message: JSON.stringify(request.data),
          buttonTitle: 'Geri dön',
        });

        setLoading(false);
      }
    }
  };

  const handleBack = () => {
    setMessage(null);
  };

  return (
    <section className='gray padding-half' id='sitecontact'>
      <div className='flex flex-row flex-center'>
        <div className='container contact-section'>
          <div className='columns'>
            <div className='column center'>
              <h3>{i18n.t('address')}</h3>
              <p>
                <strong>Siesta Bungalov</strong>
                <br />
                Melih Kibar Sahil Yolu Cad.
                <br />
                Soğuksu mahallesi No: 50
                <br />
                Kırkpınar - Sapanca / Sakarya
                <br />
                Türkiye
                <br />
                <br />
                Tesisimiz Sapanca Kırkpınar
                <br />
                sahil yolu üzerindedir.
                <br />
                <br />
                <strong>Telefon</strong> <a href='https://wa.me/905527437782'>+90 552 743 77 82</a>
              </p>
              <p>
                <a href='mailto:rezervasyon@siestabungalov.com'>rezervasyon@siestabungalov.com</a>
              </p>
              <div className='directions-container flex flex-center'>
                <span className='material-icons directions-icon'>directions_car</span>
                <a
                  href='https://www.google.com/maps/dir/40.6924382,30.2571712/Melih+Kibar+Sahil+Yolu+Cd.,+So%C4%9Fuksu,+54600+Sapanca%2FSakarya/@40.6959173,30.220925,14z/data=!3m1!4b1!4m10!4m9!1m1!4e1!1m5!1m1!1s0x14cca8f2c2e17081:0x91bd9e6c994d725b!2m2!1d30.2146006!2d40.7035352!3e0!5m1!1e4'
                  className='directions-link'
                >
                  {i18n.t('getDirections')}
                </a>
              </div>
            </div>
            <div className='column center no-select'>
              {message ? (
                <div className='contact-form loader'>
                  <h3>{message.title}</h3>
                  <p>{message.message}</p>
                  <button
                    type='button'
                    className='button button-blue button-medium'
                    onClick={() => handleBack()}
                  >
                    <span className='material-icons'>keyboard_backspace</span>
                    {message.buttonTitle}
                  </button>
                </div>
              ) : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {loading ? (
                    <div className='contact-form loader'>
                      <h3>Gönderiliyor</h3>
                      <p>Lütfen bekleyiniz...</p>
                      <img
                        src='/images/other/hourglass.gif'
                        width='64'
                        height='64'
                        alt=''
                        draggable='false'
                      />
                    </div>
                  ) : (
                    <form className='contact-form' onSubmit={handleSubmit} noValidate>
                      <h3>Mesaj gönderin</h3>
                      <div className='form-line'>
                        <div className='columns'>
                          <div className='column'>
                            <label htmlFor='name'>Adınız soyadınız</label>
                            <input
                              type='text'
                              name='name'
                              id='name'
                              autoComplete='off'
                              placeholder='Adınızı giriniz'
                              maxLength='32'
                              required
                              onChange={handleChange}
                              value={formValues.name}
                            />
                          </div>
                          <div className='column'>
                            <label htmlFor='subject'>Konu</label>
                            <select
                              name='subject'
                              id='subject'
                              onChange={handleChange}
                              value={formValues.subject}
                            >
                              <option value=''>Lütfen seçiniz</option>
                              <option value='Soru'>Soru</option>
                              <option value='Rezervasyon'>Rezervasyon</option>
                              <option value='Oneri'>Öneri</option>
                              <option value='Sikayet'>Şikayet</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='form-line'>
                        <label htmlFor='phone'>Telefon numaranız</label>
                        <input
                          type='number'
                          name='phone'
                          id='phone'
                          autoComplete='off'
                          placeholder='Telefonunuzu giriniz'
                          maxLength='15'
                          required
                          onChange={handleChange}
                          value={formValues.phone}
                        />
                      </div>
                      <div className='form-line'>
                        <label htmlFor='message'>Mesajınız</label>
                        <textarea
                          name='message'
                          id='message'
                          autoComplete='off'
                          placeholder='Mesajınızı giriniz'
                          maxLength='512'
                          required
                          onChange={handleChange}
                          value={formValues.message}
                        />
                      </div>
                      <div className='form-line'>
                        <button type='submit' className='button button-blue button-medium'>
                          <span className='material-icons'>send</span>
                          Mesajımı gönder
                        </button>
                      </div>
                    </form>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Contact;
