import { useEffect } from 'react';
import useLanguage from './hooks/useLanguage';

import Navigation from './navigation/Navigation';

import LanguageProvider from './contexts/LanguageContext';

import i18n from './i18n/i18n';

const App = () => {
  const { language } = useLanguage();

  useEffect(() => {
    i18n.locale = language.appLanguage;
    i18n.defaultLocale = language.appLanguage;
  }, [language]);

  return (
    <LanguageProvider>
      <Navigation />
    </LanguageProvider>
  );
};

export default App;
