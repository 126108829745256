import i18n from 'i18n-js';

import tr from './locales/tr';
import en from './locales/en';

i18n.translations = {
  tr,
  en,
};

i18n.locale = 'tr';
i18n.defaultLocale = 'tr';

export default i18n;
