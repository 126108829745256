import { memo, useState } from 'react';

import i18n from '../../i18n/i18n';

const AboutUs = memo(() => {
  const [show, setShow] = useState(false);

  const handleShowMore = () => {
    setShow(!show);
  };

  return (
    <section className='flex flex-center padding' id='siteabout'>
      <div className='container'>
        <div className='columns'>
          <div className='column'>
            <div className='content'>
              <h2>{i18n.t('aboutUs')}</h2>
              <p>
                Sapanca Gölü kıyısında, eşsiz doğa ve yemyeşil ağaçların arasında mavi gökyüzü ve
                oksijen deposunun tam ortasında huzuru, temiz havayı, sakinliği en derinden
                yaşayacağınız şu an iki adet olan bungalovlarımız ile sizlere unutamayacağınız anlar
                yaşatmak niyetindeyiz.
              </p>
              <p>
                Büyük şehirlerde alışık olduğunuz pek çok şeyden farklı olarak burada kuş seslerinin
                melodisi ile uyanmak, terasımızda kahvenizi yudumlarken masmavi göl manzarasını
                izlemek, Sapanca&apos;mızın eşsiz güzelliklerini keşfetmek umarız sizlere çok iyi
                gelecektir.
              </p>
              {show && (
                <p>
                  Ulaşım açısından büyük şehirlere yakınlığı nedeniyle evinizin arka bahçesi olarak
                  nitelendirilen Sapanca Kırkpınar&apos;da gölün dibinde, sadece size ait yemyeşil
                  bahçesinde, eşsiz mimarisi ile loft kattaki jakuzi ve terasındaki göl manzarasında
                  Sapanca yöresel lezzetlerinden oluşan serpme kahvaltısı ile sizlere unutulmaz ve
                  özel anılar biriktirebileceğiniz küçük dinlendirici bir mola sunuyoruz.
                </p>
              )}
              <button type='button' className='lessmore' onClick={() => handleShowMore()}>
                {show ? i18n.t('less') : i18n.t('more')}
              </button>
            </div>
          </div>
          <div className='column about right responsive-hide no-select'>
            <img
              src='/images/about/about1.jpg'
              alt='Siesta Bungalov dış görünüm'
              draggable='false'
            />
            <img src='/images/about/about2.jpg' alt='Siesta Bungalov teras' draggable='false' />
          </div>
        </div>
      </div>
    </section>
  );
});

export default AboutUs;
