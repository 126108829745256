import { useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';

const useLanguage = () => {
  const { language, addLanguage } = useContext(LanguageContext);

  return { language, addLanguage };
};

export default useLanguage;
