import Header from '../../components/Headers/Header';
import MainHero from '../../components/Heros/MainHero';
import AboutUs from '../../components/Heros/AboutUs';
import Video from '../../components/Heros/Video';
import Reservation from '../../components/Heros/Reservation';
import Contact from '../../components/Heros/Contact';
import Gallery from '../../components/Heros/Gallery';
import Faq from '../../components/Heros/Faq';
import Footer from '../../components/Footers/Footer';

const HomeScreen = () => (
  <>
    <Header />
    <MainHero />
    <AboutUs />
    <Video />
    <Reservation />
    <Contact />
    <Gallery />
    <Faq />
    <Footer />
  </>
);

export default HomeScreen;
