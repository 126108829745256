import { memo } from 'react';

import i18n from '../../i18n/i18n';

const Reservation = memo(() => (
  <section className='flex flex-column flex-center padding no-select' id='sitereservation'>
    <div className='container'>
      <div className='reservation-hero flex flex-row flex-center'>
        <p>
          {i18n.t('forBooking')}:
          <br />
          <strong className='allow-selection'>
            <a href='https://wa.me/905527437782'>+90 552 743 77 82</a>
          </strong>
        </p>
        <a className='button button-blue button-large call' href='tel:+905527437782'>
          <span className='material-icons'>call</span>
          {i18n.t('callUsNow')}
        </a>
      </div>
    </div>
  </section>
));

export default Reservation;
