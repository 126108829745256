import { memo } from 'react';
import { Link } from 'react-scroll';

import i18n from '../../i18n/i18n';

const Footer = memo(() => (
  <footer>
    <div className='footer-top flex flex-center'>
      <div className='container'>
        <div className='columns flex-start'>
          <div className='column center'>
            <h3>{i18n.t('address')}</h3>
            <p className='allow-selection'>
              Melih Kibar Sahil Yolu Cad.
              <br />
              Soğuksu mahallesi No: 50
              <br />
              Kırkpınar - Sapanca / Sakarya
            </p>
            <div className='directions-container flex flex-center'>
              <span className='material-icons directions-icon'>directions_car</span>
              <a
                href='https://www.google.com/maps/dir/40.6924382,30.2571712/Melih+Kibar+Sahil+Yolu+Cd.,+So%C4%9Fuksu,+54600+Sapanca%2FSakarya/@40.6959173,30.220925,14z/data=!3m1!4b1!4m10!4m9!1m1!4e1!1m5!1m1!1s0x14cca8f2c2e17081:0x91bd9e6c994d725b!2m2!1d30.2146006!2d40.7035352!3e0!5m1!1e4'
                className='directions-link'
              >
                {i18n.t('getDirections')}
              </a>
            </div>
          </div>
          <div className='column center'>
            <h3>{i18n.t('reservation')}</h3>
            <p>
              <a href='tel:+905527437782'>+90 552 743 77 82</a>
              <br />
              {i18n.locale === 'tr' ? (
                <a href='mailto:rezervasyon@siestabungalov.com'>rezervasyon@siestabungalov.com</a>
              ) : (
                <a href='mailto:reservation@siestabungalov.com'>reservation@siestabungalov.com</a>
              )}
            </p>
          </div>
          <div className='column center'>
            <h3>{i18n.t('menu')}</h3>
            <ul>
              <li>
                <Link to='siteabout' smooth offset={-100}>
                  {i18n.t('aboutUs')}
                </Link>
              </li>
              <li>
                <Link to='sitereservation' smooth offset={-100}>
                  {i18n.t('reservation')}
                </Link>
              </li>
              <li>
                <Link to='sitecontact' smooth offset={-100}>
                  {i18n.t('contactUs')}
                </Link>
              </li>
              <li>
                <Link to='sitegallery' smooth offset={-100}>
                  {i18n.t('photos')}
                </Link>
              </li>
              <li>
                <Link to='sitefaq' smooth offset={-100}>
                  {i18n.t('faqLong')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className='footer-bottom flex flex-center'>
      <div className='container'>
        <div className='columns'>
          <div className='column'>
            <p>
              {`${i18n.t('siteName')} © ${new Date().getFullYear().toString()} - ${i18n.t(
                'copyright'
              )}`}
              .
            </p>
          </div>
          <div className='column right'>
            <a
              href='https://www.instagram.com/sapancasiestabungalov/'
              target='_blank'
              rel='noreferrer'
              title='Siesta Bungalov Instagram'
            >
              <img
                className='social'
                src='/images/other/instagram.svg'
                alt='Siesta Bungalov instagram'
              />
            </a>
            <a
              href='https://wa.me/905527437782'
              target='_blank'
              rel='noreferrer'
              title='Siesta Bungalov Whatsapp'
            >
              <img
                className='social'
                src='/images/other/whatsapp.svg'
                alt='Siesta Bungalov whatsapp'
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
));

export default Footer;
