import { memo, useState, useEffect } from 'react';
import { Link, scroller } from 'react-scroll';

import i18n from '../../i18n/i18n';

const Header = memo(() => {
  const [menu, setMenu] = useState(false);
  const [state, setState] = useState({ className: '' });

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      if (!state.className) {
        setState({ className: 'active' });
      }
    } else {
      setState({ className: '' });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.addEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuOpen = () => {
    setMenu(!menu);
  };

  const handleMenuClick = (to) => {
    handleMenuOpen();

    scroller.scrollTo(to, {
      smooth: true,
      offset: -100,
    });
  };

  return (
    <>
      <header className={state.className}>
        <div className='flex flex-center header-inner no-select'>
          <div className='container'>
            <div className='columns'>
              <div className='column logo'>
                <Link to='sitetop' smooth>
                  <h1>{i18n.t('siteName')}</h1>
                </Link>
              </div>
              <div className='column menu responsive-hide right'>
                <ul>
                  <li>
                    <Link to='sitetop' smooth spy>
                      {i18n.t('mainPage')}
                    </Link>
                  </li>
                  <li>
                    <Link to='siteabout' smooth spy offset={-100}>
                      {i18n.t('aboutUs')}
                    </Link>
                  </li>
                  <li>
                    <Link to='sitereservation' smooth spy offset={-100}>
                      {i18n.t('reservation')}
                    </Link>
                  </li>
                  <li>
                    <Link to='sitecontact' smooth spy offset={-100}>
                      {i18n.t('contactUs')}
                    </Link>
                  </li>
                  <li>
                    <Link to='sitegallery' smooth spy offset={-100}>
                      {i18n.t('photos')}
                    </Link>
                  </li>
                  <li>
                    <Link to='sitefaq' smooth spy offset={-100}>
                      {i18n.t('faqShort')}
                    </Link>
                  </li>
                  <li>
                    <a
                      href='https://www.instagram.com/sapancasiestabungalov/'
                      target='_blank'
                      rel='noreferrer'
                      title='Siesta Bungalov Instagram'
                    >
                      <img
                        className='social'
                        src='/images/other/instagram.svg'
                        alt='Siesta Bungalov instagram'
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className='column mobile-menu right'>
                <button type='button' onClick={() => handleMenuOpen()}>
                  <span className='material-icons'>menu</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      {menu && (
        <div className='drawer-backdrop'>
          <nav className='drawer'>
            <div className='drawer-top'>
              <button type='button' onClick={() => handleMenuOpen()}>
                <span className='material-icons'>close</span>
              </button>
            </div>
            <ul>
              <li>
                <button type='button' onClick={() => handleMenuClick('sitetop')}>
                  {i18n.t('mainPage')}
                </button>
              </li>
              <li>
                <button type='button' onClick={() => handleMenuClick('siteabout')}>
                  {i18n.t('aboutUs')}
                </button>
              </li>
              <li>
                <button type='button' onClick={() => handleMenuClick('sitereservation')}>
                  {i18n.t('reservation')}
                </button>
              </li>
              <li>
                <button type='button' onClick={() => handleMenuClick('sitecontact')}>
                  {i18n.t('contactUs')}
                </button>
              </li>
              <li>
                <button type='button' onClick={() => handleMenuClick('sitegallery')}>
                  {i18n.t('photos')}
                </button>
              </li>
              <li>
                <button type='button' onClick={() => handleMenuClick('sitefaq')}>
                  {i18n.t('faqLong')}
                </button>
              </li>

              <a href='tel:+905527437782' className='button button-blue button-medium call'>
                {i18n.t('callUs')}
              </a>

              <div className='directions-container flex flex-center'>
                <span className='material-icons directions-icon'>directions_car</span>
                <a
                  href='https://www.google.com/maps/dir/40.6924382,30.2571712/Melih+Kibar+Sahil+Yolu+Cd.,+So%C4%9Fuksu,+54600+Sapanca%2FSakarya/@40.6959173,30.220925,14z/data=!3m1!4b1!4m10!4m9!1m1!4e1!1m5!1m1!1s0x14cca8f2c2e17081:0x91bd9e6c994d725b!2m2!1d30.2146006!2d40.7035352!3e0!5m1!1e4'
                  className='directions-link'
                >
                  {i18n.t('getDirections')}
                </a>
              </div>
            </ul>
          </nav>
        </div>
      )}
    </>
  );
});

export default Header;
