const galleryData = [
  {
    id: 1,
    type: 2,
    thumb: '/images/gallery/thumbnail/1.jpg',
    large: '/images/gallery/thumbnail/1.jpg',
  },
  {
    id: 2,
    type: 2,
    thumb: '/images/gallery/thumbnail/2.jpg',
    large: '/images/gallery/thumbnail/2.jpg',
  },
  {
    id: 3,
    type: 2,
    thumb: '/images/gallery/thumbnail/3.jpg',
    large: '/images/gallery/thumbnail/3.jpg',
  },
  {
    id: 4,
    type: 2,
    thumb: '/images/gallery/thumbnail/4.jpg',
    large: '/images/gallery/thumbnail/4.jpg',
  },
  {
    id: 5,
    type: 2,
    thumb: '/images/gallery/thumbnail/5.jpg',
    large: '/images/gallery/thumbnail/5.jpg',
  },
  {
    id: 6,
    type: 2,
    thumb: '/images/gallery/thumbnail/6.jpg',
    large: '/images/gallery/thumbnail/6.jpg',
  },
  {
    id: 7,
    type: 2,
    thumb: '/images/gallery/thumbnail/7.jpg',
    large: '/images/gallery/thumbnail/7.jpg',
  },
  {
    id: 8,
    type: 2,
    thumb: '/images/gallery/thumbnail/8.jpg',
    large: '/images/gallery/thumbnail/8.jpg',
  },
  {
    id: 9,
    type: 2,
    thumb: '/images/gallery/thumbnail/9.jpg',
    large: '/images/gallery/thumbnail/9.jpg',
  },
  {
    id: 10,
    type: 2,
    thumb: '/images/gallery/thumbnail/10.jpg',
    large: '/images/gallery/thumbnail/10.jpg',
  },
  {
    id: 11,
    type: 2,
    thumb: '/images/gallery/thumbnail/11.jpg',
    large: '/images/gallery/thumbnail/11.jpg',
  },
  {
    id: 12,
    type: 2,
    thumb: '/images/gallery/thumbnail/12.jpg',
    large: '/images/gallery/thumbnail/12.jpg',
  },
  {
    id: 13,
    type: 2,
    thumb: '/images/gallery/thumbnail/13.jpg',
    large: '/images/gallery/thumbnail/13.jpg',
  },
  {
    id: 14,
    type: 2,
    thumb: '/images/gallery/thumbnail/14.jpg',
    large: '/images/gallery/thumbnail/14.jpg',
  },
  {
    id: 15,
    type: 2,
    thumb: '/images/gallery/thumbnail/15.jpg',
    large: '/images/gallery/thumbnail/15.jpg',
  },
  {
    id: 16,
    type: 2,
    thumb: '/images/gallery/thumbnail/16.jpg',
    large: '/images/gallery/thumbnail/16.jpg',
  },
  {
    id: 17,
    type: 2,
    thumb: '/images/gallery/thumbnail/17.jpg',
    large: '/images/gallery/thumbnail/17.jpg',
  },
  {
    id: 18,
    type: 1,
    thumb: '/images/gallery/thumbnail/18.jpg',
    large: '/images/gallery/thumbnail/18.jpg',
  },
  {
    id: 19,
    type: 1,
    thumb: '/images/gallery/thumbnail/19.jpg',
    large: '/images/gallery/thumbnail/19.jpg',
  },
  {
    id: 20,
    type: 1,
    thumb: '/images/gallery/thumbnail/20.jpg',
    large: '/images/gallery/thumbnail/20.jpg',
  },
  {
    id: 21,
    type: 1,
    thumb: '/images/gallery/thumbnail/21.jpg',
    large: '/images/gallery/thumbnail/21.jpg',
  },
  {
    id: 22,
    type: 1,
    thumb: '/images/gallery/thumbnail/22.jpg',
    large: '/images/gallery/thumbnail/22.jpg',
  },
  {
    id: 23,
    type: 1,
    thumb: '/images/gallery/thumbnail/23.jpg',
    large: '/images/gallery/thumbnail/23.jpg',
  },
  {
    id: 24,
    type: 3,
    thumb: '/images/gallery/thumbnail/24.jpg',
    large: '/images/gallery/thumbnail/24.jpg',
  },
  {
    id: 25,
    type: 3,
    thumb: '/images/gallery/thumbnail/25.jpg',
    large: '/images/gallery/thumbnail/25.jpg',
  },
  {
    id: 26,
    type: 3,
    thumb: '/images/gallery/thumbnail/26.jpg',
    large: '/images/gallery/thumbnail/26.jpg',
  },
  {
    id: 27,
    type: 3,
    thumb: '/images/gallery/thumbnail/27.jpg',
    large: '/images/gallery/thumbnail/27.jpg',
  },
  {
    id: 28,
    type: 3,
    thumb: '/images/gallery/thumbnail/28.jpg',
    large: '/images/gallery/thumbnail/28.jpg',
  },
  {
    id: 29,
    type: 3,
    thumb: '/images/gallery/thumbnail/29.jpg',
    large: '/images/gallery/thumbnail/29.jpg',
  },
];

export default galleryData;
