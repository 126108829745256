import { Routes, Route } from 'react-router-dom';

import HomeScreen from '../screens/Home/HomeScreen';
import NotFoundScreen from '../screens/NotFound/NotFoundScreen';

const Navigation = () => (
  <Routes>
    <Route exact path='/' element={<HomeScreen />} />
    <Route path='*' element={<NotFoundScreen />} status={404} />
  </Routes>
);

export default Navigation;
