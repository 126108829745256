export default {
  siteName: 'Siesta Bungalov',
  mainPage: 'Ana sayfa',
  aboutUs: 'Hakkımızda',
  reservation: 'Rezervasyon',
  contactUs: 'Bize ulaşın',
  photos: 'Fotoğraflar',
  faqShort: 'SSS',
  faqLong: 'Sıkça sorulan sorular',
  callUs: 'Bizi arayın',
  getDirections: 'Yol tarifi alın',
  address: 'Adres',
  menu: 'Menü',
  copyright: 'Tüm hakları saklıdır',
  showAll: 'Tümünü göster',
  showImportant: 'Önemlileri göster',
  less: 'Daha az',
  more: 'Daha fazla',
  callUsNow: 'Bizi hemen arayın',
  forBooking: 'Rezervasyon ve sorularınız için',
  forInfo: 'Bilgi ve rezervasyon',
  mainHero: 'Sapanca göl kenarında muhteşem bir tatil sizi bekliyor.',
  videoLine1: `Sapanca Siesta Bungalov'da`,
  videoLine2: 'Rahatlayın ve tatilinizin',
  videoLine3: 'keyfini çıkarın.',
  videoSupport: 'Tarayıcınız video oynatma özelliklerini desteklemiyor.',
};
