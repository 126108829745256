import { memo, useState } from 'react';

import galleryData from '../../data/galleryData';

const Gallery = memo(() => {
  const [opened, setOpened] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [selectedGallery, setSelectedGallery] = useState(1);

  const prevItem = (id) => {
    const currentItem = galleryData.findIndex((x) => x.id === id);

    if (currentItem >= 0) {
      const prev = galleryData[currentItem - 1];

      if (prev) {
        setOpened(prev);
        setLoaded(false);
      }
    }
  };

  const nextItem = (id) => {
    const currentItem = galleryData.findIndex((x) => x.id === id);

    if (currentItem < galleryData.length) {
      const next = galleryData[currentItem + 1];

      if (next) {
        setOpened(next);
        setLoaded(false);
      }
    }
  };

  return (
    <>
      <section className='flex flex-column' id='sitegallery'>
        <div className='gallery-buttons'>
          <button
            type='button'
            className={selectedGallery === 1 && 'active'}
            onClick={() => setSelectedGallery(1)}
          >
            Havuzlu
          </button>
          <button
            type='button'
            className={selectedGallery === 2 && 'active'}
            onClick={() => setSelectedGallery(2)}
          >
            Jakuzili
          </button>
          <button
            type='button'
            className={selectedGallery === 3 && 'active'}
            onClick={() => setSelectedGallery(3)}
          >
            Standart
          </button>
        </div>
        <div className='gallery-items'>
          {galleryData
            .filter((x) => x.type === selectedGallery)
            .slice(0, 6)
            .map((item) => (
              <div
                aria-hidden='true'
                key={item.id.toString()}
                className='hero gallery-item no-select zoom'
                onClick={() => setOpened(item)}
                style={{ backgroundImage: `url('${item.thumb}')` }}
              >
                <div className='inner flex flex-center'>
                  <span className='material-icons'>add</span>
                </div>
              </div>
            ))}
        </div>
      </section>
      {opened && (
        <>
          {loaded ? 'loaded' : 'loading'}
          <img
            alt=''
            src={opened.large}
            style={{ display: 'none' }}
            onLoad={() => setLoaded(true)}
          />
          <div
            className='gallery-opened hero'
            style={{ backgroundImage: `url('${opened.large}')` }}
          >
            <div
              className='gallery-prev flex flex-center center no-select'
              aria-hidden='true'
              onClick={() => prevItem(opened.id)}
            >
              <span className='material-icons'>arrow_back_ios</span>
            </div>
            <div
              className='gallery-next flex flex-center center no-select'
              aria-hidden='true'
              onClick={() => nextItem(opened.id)}
            >
              <span className='material-icons'>arrow_forward_ios</span>
            </div>
            <div
              aria-hidden='true'
              className='gallery-closer flex flex-center no-select'
              onClick={() => setOpened(null)}
            >
              <span className='material-icons'>close</span>
            </div>
          </div>
        </>
      )}
    </>
  );
});

export default Gallery;
