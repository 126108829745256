export default {
  siteName: 'Siesta Bungalov',
  mainPage: 'Home',
  aboutUs: 'About us',
  reservation: 'Reservation',
  contactUs: 'Contact us',
  photos: 'Photo gallery',
  faqShort: 'FAQ',
  faqLong: 'Frequently asked questions',
  callUs: 'Call us',
  getDirections: 'Get directions',
  address: 'Address',
  menu: 'Menu',
  copyright: 'All rights reserved',
  showAll: 'Show all',
  showImportant: 'Show important',
  less: 'Show less',
  more: 'Show more',
  callUsNow: 'Call us now',
  forBooking: 'For booking and questions',
  forInfo: 'For info and booking',
  mainHero: 'A wonderful vacation awaits you by the lake in Sapanca.',
  videoLine1: ``,
  videoLine2: 'Relax and enjoy your vacation',
  videoLine3: 'in Siesta Bungalov.',
  videoSupport: 'Your browser does not support the video tag.',
};
